/* roboto-cyrillic-ext-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-cyrillic-ext-300-normal.d0441335.woff2) format('woff2'), url(/static/media/roboto-cyrillic-ext-300-normal.bce05c9d.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* roboto-cyrillic-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-cyrillic-300-normal.9b9ec295.woff2) format('woff2'), url(/static/media/roboto-cyrillic-300-normal.3c86b7db.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* roboto-greek-ext-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-greek-ext-300-normal.c2be5367.woff2) format('woff2'), url(/static/media/roboto-greek-ext-300-normal.5392caae.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* roboto-greek-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-greek-300-normal.dcdaee37.woff2) format('woff2'), url(/static/media/roboto-greek-300-normal.43c3a71d.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* roboto-vietnamese-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-vietnamese-300-normal.48c684d9.woff2) format('woff2'), url(/static/media/roboto-vietnamese-300-normal.3906643a.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* roboto-latin-ext-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-latin-ext-300-normal.716871ec.woff2) format('woff2'), url(/static/media/roboto-latin-ext-300-normal.2a608845.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* roboto-latin-300-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-latin-300-normal.b9c29351.woff2) format('woff2'), url(/static/media/roboto-latin-300-normal.b19cb2c7.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
/* roboto-cyrillic-ext-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-cyrillic-ext-400-normal.c00467dc.woff2) format('woff2'), url(/static/media/roboto-cyrillic-ext-400-normal.13e4326a.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* roboto-cyrillic-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-cyrillic-400-normal.d9ac47c7.woff2) format('woff2'), url(/static/media/roboto-cyrillic-400-normal.c57da4a6.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* roboto-greek-ext-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-greek-ext-400-normal.35de3738.woff2) format('woff2'), url(/static/media/roboto-greek-ext-400-normal.3d5467e6.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* roboto-greek-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-greek-400-normal.28668857.woff2) format('woff2'), url(/static/media/roboto-greek-400-normal.e5bdd5a0.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* roboto-vietnamese-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-vietnamese-400-normal.ca3b09b6.woff2) format('woff2'), url(/static/media/roboto-vietnamese-400-normal.08a0a460.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* roboto-latin-ext-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-latin-ext-400-normal.87ace200.woff2) format('woff2'), url(/static/media/roboto-latin-ext-400-normal.c9b7ba0d.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* roboto-latin-400-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-latin-400-normal.15d9f621.woff2) format('woff2'), url(/static/media/roboto-latin-400-normal.c7ab406d.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
/* roboto-cyrillic-ext-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-cyrillic-ext-500-normal.2742d81a.woff2) format('woff2'), url(/static/media/roboto-cyrillic-ext-500-normal.5411753b.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* roboto-cyrillic-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-cyrillic-500-normal.7b08b9e1.woff2) format('woff2'), url(/static/media/roboto-cyrillic-500-normal.0a902cf6.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* roboto-greek-ext-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-greek-ext-500-normal.e7b7001d.woff2) format('woff2'), url(/static/media/roboto-greek-ext-500-normal.dee4262c.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* roboto-greek-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-greek-500-normal.53f395eb.woff2) format('woff2'), url(/static/media/roboto-greek-500-normal.a53c4824.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* roboto-vietnamese-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-vietnamese-500-normal.7cda2cfe.woff2) format('woff2'), url(/static/media/roboto-vietnamese-500-normal.8d5980bf.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* roboto-latin-ext-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-latin-ext-500-normal.e36fccd0.woff2) format('woff2'), url(/static/media/roboto-latin-ext-500-normal.62dab7c7.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* roboto-latin-500-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-latin-500-normal.3a44e06e.woff2) format('woff2'), url(/static/media/roboto-latin-500-normal.ad4c3ac0.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
/* roboto-cyrillic-ext-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-cyrillic-ext-700-normal.e0bc9313.woff2) format('woff2'), url(/static/media/roboto-cyrillic-ext-700-normal.c3f88f4f.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* roboto-cyrillic-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-cyrillic-700-normal.6f112ec2.woff2) format('woff2'), url(/static/media/roboto-cyrillic-700-normal.4ba1f101.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* roboto-greek-ext-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-greek-ext-700-normal.2953af00.woff2) format('woff2'), url(/static/media/roboto-greek-ext-700-normal.a97d524f.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* roboto-greek-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-greek-700-normal.3f8b2aa4.woff2) format('woff2'), url(/static/media/roboto-greek-700-normal.2cbfcf57.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* roboto-vietnamese-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-vietnamese-700-normal.cdaab836.woff2) format('woff2'), url(/static/media/roboto-vietnamese-700-normal.c1aa093e.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* roboto-latin-ext-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-latin-ext-700-normal.deb26e9b.woff2) format('woff2'), url(/static/media/roboto-latin-ext-700-normal.6de4f5eb.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* roboto-latin-700-normal */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-latin-700-normal.e9f5aaf5.woff2) format('woff2'), url(/static/media/roboto-latin-700-normal.091ab374.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
